import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import {
  Box,
  Button,
  EntryList,
  Flex,
  Grid,
  Icon,
  Loader,
  Tag,
  Text,
  Tooltip,
} from '~/components/blocks';
import { InsurnceCardCollapse, ReceptionTypeIcon } from '~/components/partials';
import { DraftAppointmentStatusLabel } from '~/components/partials/DraftAppointmentStatusLabel';
import { ReceiveOption } from '~/components/partials/ReceiveOption';
import { TabMap } from '~/constants/tab';
import { filePageInfoState, tabState } from '~/state/partials/patient_karte/atoms';
import { translateReason } from '~/utils/draft_appointments';

import { DispensingRequestCollapse } from './DispensingRequestCollapse';
import { useFetchDispensingRequest } from './DispensingRequestCollapse/use-fetch-dispensing-request';
import { MemoField } from './MemoField';
import { StaffActionInfo } from './StaffActionInfo';
import { useFetchDraftAppointment } from './use-fetch-draft-appointment';
import { useUpdateMemo } from './use-update-memo';

type Props = {
  draftAppointmentId: string;
};

const CustomTag = styled(Tag)(({ theme }) =>
  css({
    background: theme.colors.colorPallete.grey04,
    border: 'none',
  }),
);

export const DraftAppointment = React.memo((props: Props) => {
  const theme = useTheme();
  const { loading, draftAppointment } = useFetchDraftAppointment(props.draftAppointmentId);
  const {
    loading: dispensingRequestLoading,
    timeout,
    error,
    dispensingRequest,
    medicalInstitution,
    willReceivePrescription,
    hasReceivedPrescription,
  } = useFetchDispensingRequest(props.draftAppointmentId);
  const { updating, handleChangeMemo } = useUpdateMemo(props.draftAppointmentId);

  const isActivePatient = !!draftAppointment?.patient?.active;
  const patientId = draftAppointment?.patient?.id;

  const handleClickCertificate = useRecoilCallback(
    ({ set }) =>
      () => {
        if (patientId) {
          set(tabState, { type: TabMap.file });
          set(filePageInfoState, (_state) => ({
            ..._state,
            patientId,
            page: 1,
            filter: { sent: false, received: true },
          }));
        }
      },
    [patientId],
  );

  const MedixsId = styled('div')(({ theme }) =>
    css({
      display: 'inline-flex',
      alignItems: 'center',
      backgroundColor: theme.colors.background.bg,
      padding: `${theme.space.s} ${theme.space.l}`,
      borderRadius: theme.radii.half,
    }),
  );

  if (!draftAppointment) {
    return null;
  }

  return (
    <Box position="relative" height="100%" overflow="auto" padding={theme.space.l}>
      {(loading || dispensingRequestLoading) && (
        <Loader open inside transparent appearance="white" />
      )}
      <>
        <MedixsId>
          <Text size="xs" fontWeight="bold">
            受付ID：
          </Text>
          <Icon icon="attention" size="m" />
          <Text color="pink" fontWeight="bold" size="s">
            未連携
          </Text>
        </MedixsId>
        <Grid
          gridTemplateColumns="min-content auto"
          gridTemplateRows="50px min-content"
          paddingTop={theme.space.m}
          alignItems="center"
        >
          <Flex justifyContent="center">
            <ReceptionTypeIcon telemedicine={draftAppointment.telemedicine} size="xxxl" mr="xxs" />
          </Flex>
          <Flex justifyItems="start" alignItems="center">
            {draftAppointment.telemedicine ? (
              <Text fontWeight="bold" size="m" paddingLeft={theme.space.s}>
                オンライン服薬指導
              </Text>
            ) : (
              <Text fontWeight="bold" size="m" paddingLeft={theme.space.s}>
                対面服薬指導
              </Text>
            )}
            <Box marginLeft="auto">
              <DraftAppointmentStatusLabel status={draftAppointment.status} />
            </Box>
          </Flex>
          <Flex justifyContent="center">
            <Icon icon="time" size="xl" />
          </Flex>
          <Flex justifyItems="start">
            <Text fontWeight="bold" size="m" ml={theme.space.s}>
              未確定
            </Text>
          </Flex>
        </Grid>
        <StaffActionInfo
          draftAppointment={draftAppointment}
          medicalInstitutionName={medicalInstitution?.name}
        />
        <ReceiveOption deliveryMethod={draftAppointment.deliveryMethod} />
        <DispensingRequestCollapse
          medicalInstitution={medicalInstitution}
          draftAppointmentId={props.draftAppointmentId}
          hasReceivedPrescription={hasReceivedPrescription}
          willReceivePrescription={willReceivePrescription}
          dispensingRequest={dispensingRequest}
          dispensingRequestError={error}
          isDispensingRequestTimeout={timeout}
        />
        <InsurnceCardCollapse
          patientId={patientId}
          isActivePatient={isActivePatient}
          onClickCertificate={handleClickCertificate}
        />
        <EntryList mt={theme.space.l}>
          <Tooltip
            content={
              <>
                患者のCLINICSアプリにて、
                <br />
                お薬手帳の閲覧が許可されていないため
                <br />
                表示できません
              </>
            }
          >
            <div>
              <Button wide="fill" disabled>
                <Icon icon="medicalnote" size="xxl" />
                お薬手帳
              </Button>
            </div>
          </Tooltip>
        </EntryList>
        <EntryList mt={theme.space.l}>
          <EntryList.Head>申し込み日時</EntryList.Head>
          <EntryList.Body>
            <Flex>
              <Box>
                <Text size="m">-</Text>
              </Box>
            </Flex>
          </EntryList.Body>
        </EntryList>
        <EntryList mt={theme.space.l}>
          <EntryList.Head>メモ</EntryList.Head>
          <EntryList.Body>
            <CustomTag>
              <Flex alignItems="center">
                <Icon icon="smartphone" size="l" />
                <Text size="m">CLINICS会員</Text>
              </Flex>
            </CustomTag>
            <MemoField
              disabled={updating}
              value={draftAppointment.description}
              onChange={handleChangeMemo}
            />
          </EntryList.Body>
        </EntryList>
      </>
      {(draftAppointment.status === 'cancelled' ||
        draftAppointment.status === 'not_prescribed') && (
        <EntryList mt={theme.space.l}>
          <EntryList.Head>キャンセル内容</EntryList.Head>
          <EntryList.Body>
            <Text fontWeight="bold">{translateReason(draftAppointment)}</Text>
          </EntryList.Body>
        </EntryList>
      )}
    </Box>
  );
});

DraftAppointment.displayName = 'DraftAppointmentOnReceptionPane';
