import React, { ComponentProps } from 'react';

import { Chip } from '~/components/blocks';
import { ReceptionStatus } from '~/components/partials/ReceptionStatus';
import { AppointmentStatus, ReceptionUberDeliveryFragment } from '~/graphql';

import { UberDeliveryAppointmentStatusLabel } from '../UberDeliveryAppointmentStatusLabel';

type Props = {
  size?: ComponentProps<typeof Chip>['size'];
  status: AppointmentStatus;
  uberDelivery?: ReceptionUberDeliveryFragment | null | undefined;
  isSameDayDelivery?: boolean;
};

export const AppointmentStatusLabel = (props: Props) => {
  const { status, uberDelivery, isSameDayDelivery } = props;
  const buttonProps: {
    color: ComponentProps<typeof Chip>['color'];
    textColor: ComponentProps<typeof Chip>['textColor'];
    label: string;
  } = {
    color: 'green',
    label: '未受付',
    textColor: 'white',
  };

  const uberStatusLabel = UberDeliveryAppointmentStatusLabel({
    status,
    uberDelivery,
    isSameDayDelivery,
  });

  if (uberStatusLabel) {
    return uberStatusLabel;
  }

  switch (status) {
    case AppointmentStatus.Cancelled:
    case AppointmentStatus.Noshow:
      buttonProps.color = 'yellow';
      buttonProps.label = 'キャンセル';
      buttonProps.textColor = 'black';
      break;
    case AppointmentStatus.Booked:
      buttonProps.color = 'blue';
      buttonProps.label = '指導待ち';
      break;
    case AppointmentStatus.WaitingForCharge:
      buttonProps.color = 'pink';
      buttonProps.label = '未会計';
      break;
    case AppointmentStatus.Charged:
      buttonProps.color = 'grey01';
      buttonProps.label = '配達待ち';
      break;
    case AppointmentStatus.Finished:
      buttonProps.color = 'grey03';
      buttonProps.label = '完了';
      buttonProps.textColor = 'black';
      break;
  }

  return (
    <ReceptionStatus
      label={buttonProps.label}
      chipColor={buttonProps.color}
      labelColor={buttonProps.textColor}
    />
  );
};
