import React, { ComponentProps } from 'react';

import { Chip } from '~/components/blocks';
import { ReceptionStatus } from '~/components/partials/ReceptionStatus';
import { DraftAppointmentStatus } from '~/graphql';

type Props = {
  size?: ComponentProps<typeof Chip>['size'];
  status: DraftAppointmentStatus;
};

export const DraftAppointmentStatusLabel = (props: Props) => {
  const { status } = props;

  if (
    status === DraftAppointmentStatus.Cancelled ||
    status === DraftAppointmentStatus.NotPrescribed
  ) {
    return <ReceptionStatus label="キャンセル" chipColor="yellow" labelColor="black" />;
  }

  if (status === DraftAppointmentStatus.Available) {
    return (
      <ReceptionStatus
        label="未受付"
        chipColor="green"
        labelColor="white"
        detailedStatus={{
          label: '処方箋到着待ち',
          color: 'pink',
          iconName: 'drug',
        }}
      />
    );
  }

  if (status === DraftAppointmentStatus.WaitForBooking) {
    return (
      <ReceptionStatus
        label="未受付"
        chipColor="green"
        labelColor="white"
        detailedStatus={{
          label: '日時指定待ち',
          color: 'pink',
          iconName: 'calender',
        }}
      />
    );
  }

  return <ReceptionStatus label="完了" chipColor="grey03" labelColor="black" />;
};
